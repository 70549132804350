if (module.hot) {
  module.hot.accept()
}

//  wrap fetch with XMLHttpRequest if fetch not available
if (!window.fetch) {
  window.fetch = function (url, options) {
    return new Promise(function (resolve, reject) {
      var xhr = new XMLHttpRequest()
      xhr.open(options ? options.method || "GET" : "GET", url)
      xhr.onload = function () {
        resolve(new Response(xhr.responseText, { status: xhr.status }))
      }
      xhr.onerror = function () {
        reject(new TypeError("Network request failed"))
      }
      xhr.send(options ? options.body : undefined)
    })
  }
}

window.addRow = function (label, value) {
  var tr, td
  tr = document.createElement("tr")
  td = document.createElement("td")
  td.innerHTML = label
  td.className = "first-col"
  tr.appendChild(td)
  td = document.createElement("td")
  td.innerHTML = value
  td.style.maxWidth = "80%"
  td.style.wordBreak = "break-all"

  tr.appendChild(td)

  return tr
}
